<!-- Order -->
<template>
  <div >
    <div slot="header" class="modal-header popup-header">
			<span class="modal-title-popup"><i class="fa fa-file" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Orders-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
            <div class="row justify-content-center">
              <div class="col-xl-12">
                  <!--begin: Orders Form-->
                <el-form ref="form" :model="form" auto-complete="false" style="padding:0px !important"> 
                  <div class="row">
                          <div class="col-xl-12 ">  
                                 <el-table :data="form.delivery_items" stripe border fit @selection-change="handleSelectionChange">                                                         
                                  <el-table-column
                                    type="selection"
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Product #" width="100">
                                       <template slot-scope="scope">
                                         <span>{{form.delivery_items[scope.$index].product.sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.delivery_items[scope.$index].product.name}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Quantity" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.delivery_items[scope.$index].delivery_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Delivered" width="150" align="center">
                                     <template slot-scope="scope">
                                       <el-input-number class="return_product" v-model="form.delivery_items[scope.$index].delivered" auto-complete="nope"  :controls="false"  
                                       :max="form.delivery_items[scope.$index].delivery_quantity" :disabled="!scope.row.edited"></el-input-number>                                          
                                      </template>                            
                                  </el-table-column>                          
                                </el-table>  
                                <span class="red">{{errMsg}}</span> 
                          </div>
                      </div>
                  <div class="row">                     
                    <div class="col-sm-12 mt-3">
                      <el-form-item label="Delivery Verified Document">
                         <el-upload class=""
                            action
                            accept="image/jpeg,image/png"
                            list-type="picture-card"
                            :auto-upload="false"
                            :file-list="attachments"                                                    
                            :on-remove="removeImage"
                            :before-remove="beforeRemove"
                            :on-change="addAttachment"
                            multiple>
                            <el-button slot="trigger" size="small" type="primary" >Click Upload</el-button>
                          </el-upload>
                      </el-form-item>
                       <img width="100%" :src="form.dialogImageUrl" alt="">   
                       <span class="red">{{docError}}</span> 
                    </div>        
                      <div class="col-sm-10">
                        <el-form-item
                          label="Other Comments or Special Instructions"
                          prop="comments"
                        >
                        <el-input
                          type="textarea"
                          :rows="3"
                          v-model="form.comments"
                          @input.native="capitalize"
                        ></el-input>
                      </el-form-item>
                      </div>
                    <div class="col-sm-12 row">
                       <div class="col-sm-5">                        
                          <el-form-item label="Verified Person Name" >
                            <template >
                              <el-input v-model="form.delivery_verified_by" @input.native="capitalize" ></el-input>
                          </template>   
                          </el-form-item>
                       </div>
                       <div class="col-sm-5">     
                          <el-form-item label="Driver Name" >
                            <template >
                              <el-input v-model="form.driver_name" @input.native="capitalize" :class=" form.errors.has('driver_name') ? 'error required' : 'required' "></el-input>
                          </template>                       
                          </el-form-item> 
                          <span class="form-err" v-if="form.errors.has('driver_name')" >{{form.errors.get('driver_name')}}</span> 
                       </div> 
                       <div class="col-sm-2" style="margin-top:20px;">                    
                          <button class="rounded-3 btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="addDocument()">{{btn_text}}</button>                    
                        </div>                    
                    </div>                  
                  </div>
                   
                  </el-form>
                  <!--end: Orders Form-->
              </div>
            </div>
            <!--end: Orders Body-->
          </div>
        </div>
        <!--end: Orders-->
    </div>
  </div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getUsers, getDocumentList} from "@/api/customer";
import CreateDocument from '@/components/CreateCustomer/document_type';
export default {
  name: 'add-order',
  props: {
    delivery :{
      type: Object,
    },
    order_id: {
      type: Number,             	
	  },
    page_name:{
      type:String
    },
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'DELIVERY DETAILS'}
      }
	  }
  },
  data() {
    return {
      loading:false,
      error: null,
      btn_text : 'Completed',     
      form : new Form({ 
        dialogImageUrl: '',        
        order_id : null,       
        delivery_document :null,
        delivery_verified_by :null,
        comments:null,
        attachments:[],
        attachment:[],
        driver_name:null, 
        delivery_items: [{
          'delivered' : null,
        }          
        ], 
        edited:false
         
      }), 
           
      credit_list: [],
      document_list:[],
      attachments:[],     
      api_url :'order/delivery/completed',
      users:[],      
      delivery_document: '/ctshub/media/bg/profile-img.png',
      errMsg:null,
      docError:null,
     
    }
  },
  components: {},
  mounted() {
    this.form.customer_id = this.customer_id;
     globalEvents.$on('DocumentType', type => {
               if (type) {
               this.document_list.push(type)
               this.form.document_name = type.document_name               
               }})  
  },
  methods: {
    closeForm() {
    	if (!this.form.submiting) {
      	globalEvents.$emit('CreditAdded', false)
      	this.$emit('close')
    	}
	  },
     addDocuments(event){                
        this.document_name1 =''; this.document_name2 = '', this.document_name3 = '';
             let i=0;
             for(i=0;i<event.length;i++){
                
                if(event[i]=='Driving license'){
                   this.document_name1 = event[i];
                }
                  if(event[i]=='Passport'){
                   this.document_name2 = event[i];
                }
                  if(event[i]=='Health Card'){
                   this.document_name3 = event[i];
                }
                
             }
          },
       addAttachment (file) {
          let fileDocument = [];
          fileDocument.push(file);
             this.form.attachment = [];              
         fileDocument.map(async(attachment) => {
            let fileName = attachment.name;
            let blob = this.blobIsExists(fileName);           
            if(blob){
                this.form.attachments.push({image_path:fileName,image_blob:blob});               
            }else if(file != null){               
               blob = await this.readFile(attachment.raw);   
               this.form.attachments.push({image_path:fileName,image_blob:blob});                            
            }
          });
       
        },
      beforeRemove(file) {       
          return this.$confirm(`Do you really want to delete ${ file.name }？`);
      },
       removeImage (file,fileList) { 
           this.form.attachments = []; 
           fileList.map(async(attachment) => {
            let fileName = attachment.name;
            let blob = this.blobIsExists(fileName);
            if(blob){
                this.form.attachments.push({image_path:fileName,image_blob:blob});              
            }else if(file != null){   
               
                if(attachment.status === "success"){
                  this.form.attachments.push({image_path:attachment.name,image_blob:attachment.url});
               }else{
                blob = await this.readFile(attachment.raw);
                this.form.attachments.push({image_path:fileName,image_blob:blob});
               }
            }
          }); 
        
        },
         readFile(file) {
           return new Promise((resolve) => {
              
                    const fReader = new FileReader();
                    fReader.onload = () => {
                       resolve(fReader.result);
                    }
                    fReader.readAsDataURL(file);
           })
        },
        blobIsExists(filename){
            let blob = null;
            this.attachments.map((attachment) => {
              if(attachment.name === filename){
                blob = attachment.blob;
              }
            })
            return blob;
        },
    getUsername(){
      this.loading = true
      getUsers().then(response => {
        this.users = response.data.data
        this.loading = false
      })
    },
    handleSelectionChange(val) { 
        this.multipleSelection = val;  
       this.form.delivery_items.map((check)=>{
          check.delivered = 0;
          check.edited = false       
          val.map((selected)=>{
            if(check.id == selected.id){
                check.delivered =  check.delivery_quantity 
                check.edited = true                
              }
            })
       })       
      
      },
    getDocumentList(){
      this.loading = true
      getDocumentList().then(response => {
        this.document_list = response.data.data
        this.loading = false
      })
    },
     onDocFileChange(e) {
               let files = e.target.files || e.dataTransfer.files;
   
               if (files.length <= 0) {
                 return;
               }
              
                  this.form.delivery_document = files[0];
                  this.delivery_document = URL.createObjectURL(files[0]);              
             
           },
   
    addDocument: function() {
     let me = this
       this.form.delivery_items.forEach(function (value) {
          me.errMsg = ''
        if (value.delivered == null) {
          me.errMsg = 'delivery quantity is required';
           
        }
        
      });
      
        //  me.docError = '';
        // if(this.form.attachments.length == '0'){
        // me.docError = "Delivery document is required"
        // }
        if(!this.errMsg){
        this.form.order_id = this.order_id; 
        this.loading = true;               
                  this.form.post(this.api_url).then((response) => {
                    if(response.status){
                      	globalEvents.$emit('document', false)
                        this.$emit('close')
                        this.$showResponse('success', response.message);
                        if(this.page_name=='driver update'){
                          this.$router.push({ path: '/driver/update/delivery' });
                        }else{
                          this.$router.push({ path: '/all/order/list' });
                        }
                        
                    }
                    this.loading=false;
                  }).catch((error) => {
                    console.log(error)
                    this.loading = false;
                  })  
        }      

    }, 
    CreateDocument(){
                  this.$modal.show(CreateDocument, {
                     }, { draggable: true, transition: 'pop-out', height: 'auto', clickToClose: true, adaptive: true , width: '450px' });
               },   
      },
  
  created(){
    //console.log(this.inventory_id);
    this.form.delivery_items = this.delivery.order_items;    
    this.getUsername();
    this.getDocumentList();
    
  },
  
}
</script>

<style>
 
 .vm--modal {    
    overflow: unset !important;
}


.document_name{
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}


</style>

